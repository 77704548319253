/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/

.image{
 
  text-align: center;
  /* margin-top: 150px; */
}

@media (max-width:999px) {
  .image .i{
    width:40vh;
    height:40vh;
    /* margin-top: 0px; */
  }
}



.about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }

  .about .content p{
    font-size: 17px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding-left: 28px;
    position: relative;
  }
  
  .about .content ul li+li {
    margin-top: 10px;
  }
  
  .about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #2487ce;
    line-height: 1;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  .about .content .btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #2487ce;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #2487ce;
  }
  
  .about .content .btn-learn-more:hover {
    background: #2487ce;
    color: #fff;
    text-decoration: none;
  }

  .about #section{
    margin-top: 60px;
  }

  .core_value .icon-boxes {
    margin-top: 40px;
  }
  
  .core_value .icon-box {
    padding: 50px 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
    text-align: center;
  }
  
  .core_value .icon-box .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .core_value .icon-box .title a {
    color: #124265;
    transition: 0.3s;
  }
  
  .core_value .icon-box .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  
  .core_value .icon-box .icon {
    margin-bottom: 20px;
    padding-top: 10px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    font-size: 36px;
    line-height: 1;
    color: #2487ce;
  }
  
  .core_value .icon-box:hover {
    transform: scale(1.08);
  }
  
  .core_value .icon-box:hover .title a {
    color: #2487ce;
  }