.image {

    text-align: center;
    /* flex-wrap:wrap; */
    /* margin-top: 150px; */
  }
  
  @media (max-width:999px) {
    .image .i {
      width: 40vh;
      height: 40vh;
      /* margin-top: 0px; */
    }
  }

  .t{
    padding-top: 50px;
 } 

 .a{
    border: 2px dotted;
    padding: 50px;
 }

 .pimg{
    
    vertical-align: middle;
        max-width: 100%;
        height: auto;
    
 }

 .propic{
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0 70px 0;
 }