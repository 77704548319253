/*--------------------------------------------------------------
# services
--------------------------------------------------------------*/

.image {

    text-align: center;
    /* flex-wrap:wrap; */
    /* margin-top: 150px; */
  }
  
  @media (max-width:999px) {
    .image .i {
      width: 40vh;
      height: 40vh;
      /* margin-top: 0px; */
    }
  }

.work-box {
    margin-bottom: 3rem;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #fff;
    /* height:50%; */
    /* width: 80%;   */
    text-align: center;
    border: 1px dotted;
    /* border-radius: 15px; */
    
    /* box-shadow:  #5e5e5e; */
  }
  
  .work-box:hover img {
    transform: scale(1.3);
  }
  
  .work-img {
    display: block;
    overflow: hidden;
  }
  
  .work-img img {
    transition: all 1s;
  }
  
  .work-content {
    padding: 2rem 3% 1rem 4%;
  }
  
  .work-content .w-more {
    color: #4e4e4e;
    font-size: 0.8rem;
  }
  
  .work-content .w-more .w-ctegory {
    color: #0078ff;
  }
  
  .work-content .w-like {
    font-size: 2.5rem;
    color: #0078ff;
    float: right;
  }
  
  .work-content .w-like a {
    color: #0078ff;
  }
  
  .work-content .w-like .num-like {
    font-size: 0.7rem;
  }
  
  .w-title {
    font-size: 1.2rem;
  }