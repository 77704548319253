/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  /* height: 100%; */
  height: 100vh;
  position: relative;
  background: url("../../../public/img/Home_Image.jpg") top center;
  background-size: cover;
  position: relative;
}

#hero:before {
  content: "";
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  padding-top: 150px;
  height: 100vh;
}

#hero h1 {
  margin: 0;
  font-size: 50px;
  font-weight: 700;
  line-height: 72px;
  color: #124265;
  font-family: "Poppins", sans-serif;
}

#hero h2 {
  color: #5e5e5e;
  margin: 10px 0 0 0;
  font-size: 22px;
}

#hero .btn-get-started {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 14px 50px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  background: #2487ce;
}

#hero .btn-get-started:hover {
  background: #3194db;
}



@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
@media (min-width: 1299px){
  #hero .container {
    padding-top: 350px;
    height: 100vh;
  }
}

@media (max-height: 800px) {
  #hero {
    height: auto;
  }
}

@media (max-width: 992px) {
  #hero {
    height: auto;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

/*  */

#wedo h5 {
  text-align: justify;
  font-size:18px;
}
/*  */

/*--------------------------------------------------------------
# services
--------------------------------------------------------------*/

.work-box {
  margin-bottom: 3rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #fff;
  /* height:50%; */
  /* width: 80%;   */
  text-align: center;
  border: 1px dotted;
  /* border-radius: 15px; */

  /* box-shadow:  #5e5e5e; */
}

.work-box:hover img {
  transform: scale(1.3);
}

.work-img {
  display: block;
  overflow: hidden;
}

.work-img img {
  transition: all 1s;
}

.work-content {
  padding: 2rem 3% 1rem 4%;
}

.work-content .w-more {
  color: #4e4e4e;
  font-size: 0.8rem;
}

.work-content .w-more .w-ctegory {
  color: #0078ff;
}

.work-content .w-like {
  font-size: 2.5rem;
  color: #0078ff;
  float: right;
}

.work-content .w-like a {
  color: #0078ff;
}

.work-content .w-like .num-like {
  font-size: 0.7rem;
}

.w-title {
  font-size: 1.2rem;
}

