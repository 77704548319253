.image {

    text-align: center;
    /* flex-wrap:wrap; */
    /* margin-top: 150px; */
  }
  
  @media (max-width:999px) {
    .image .i {
      width: 40vh;
      height: 40vh;
      /* margin-top: 0px; */
    }
  }

  .t{
    padding-top: 50px;
 } 

 .a{
    border: 2px dotted;
    padding: 50px;
 }


 /* .about .content h3 {
    font-weight: 600;
    font-size: 26px;
  } */
  
  .service .icon-boxes {
    /* margin-top: 40px; */
  }
  
  .service .icon-box {
    padding: 30px 30px;
    margin: 10px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
    text-align: center;
  }
  
  .service .icon-box .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .service .icon-box .title a {
    color: #124265;
    transition: 0.3s;
  }
  
  .service .icon-box .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  
  .service .icon-box .icon {
    margin-bottom: 20px;
    padding-top: 10px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    font-size: 36px;
    line-height: 1;
    color: #2487ce;
  }
  
  .service .icon-box:hover {
    transform: scale(1.08);
  }
  
  .service .icon-box:hover .title a {
    color: #2487ce;
  }